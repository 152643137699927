var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-wrapper",staticStyle:{"background-color":"var(--v-header-bg)"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticStyle:{"height":"300px"},attrs:{"cols":"12","xs":"12"}},[_c('word-cloud',{attrs:{"dataset":_vm.dataset}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xs":"12"}},[_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xs":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-1","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-filter ")]),_vm._v(" Filter ")],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('div',{staticClass:"filter-container pa-5",staticStyle:{"background":"#fff"}},[_c('element-renderer',{attrs:{"element":_vm.filterElement}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.filterMenu = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.search}},[_vm._v(" Search ")])],1)],1)])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tickets.items},scopedSlots:_vm._u([{key:"item.risk_escalation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-3"},[_c('v-progress-circular',{attrs:{"color":_vm.getColor(item),"width":5,"value":item.risk_escalation,"size":50}},[_c('span',[_vm._v(" "+_vm._s(item.risk_escalation)+"% ")])])],1)]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(item.content)+" ")]),(item.tags && item.tags.length)?_c('div',{staticClass:"pb-3"},[_vm._l((item.tags),function(tag){return [_c('v-chip',{key:tag.id,staticClass:"mt-5 mb-2 mr-2",attrs:{"color":tag.color,"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(tag.icon)+" ")]),_vm._v(" "+_vm._s(tag.name)+" ")],1)]})],2):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-3"},[_c('span',[_vm._v(" "+_vm._s(_vm.daysAgo(item))+" ")])])]}}])})],1),_c('v-col',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","no-click-animation":true},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticClass:"pl-5",attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" ORR Model Builder - Build an AI model to find correlations in ORR data and simulate and predict future data ")]),_c('v-spacer')],1),(_vm.model && _vm.model.datasets)?_c('builder',{key:_vm.model.model_code}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }