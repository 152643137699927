<template>
  <div class="banner-wrapper" style="background-color: var(--v-header-bg)">
    <v-container fluid>
      <!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->

      <v-row>
        <v-col cols="12" xs="12" style="height: 300px">
          <word-cloud :dataset="dataset" />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12">
          <v-spacer />
        </v-col>
        <v-col cols="12" md="12" sm="12" lg="12" xs="12">
          <v-card>
            <v-card-title>
              <v-spacer />
              <!-- <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              /> -->
              <v-menu
                v-model="filterMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn color="blue darken-1" text v-bind="attrs" v-on="on">
                    <v-icon left dark> mdi-filter </v-icon>
                    Filter
                  </v-btn>
                </template>
                <div class="filter-container pa-5" style="background: #fff">
                  <element-renderer :element="filterElement" />
                  <v-row no-gutters>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      text
                      @click="filterMenu = false"
                    >
                      Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="search">
                      Search
                    </v-btn>
                  </v-row>
                </div>
              </v-menu>
            </v-card-title>
            <!-- <v-data-table
              :headers="headers"
              :items="models"
              :search="search"
              :loading="loading"
            >
              <template #item.actions="{ item }">
                <v-icon small class="mr-2" @click="setModel(item)">
                  mdi-pencil
                </v-icon>
              </template>
              <template #no-results>
                <v-alert :value="true" color="error" icon="warning">
                  Your search for "{{ search }}" found no results.
                </v-alert>
              </template>
            </v-data-table> -->

            <v-data-table
              :headers="headers"
              :items="tickets.items"
              class="elevation-1"
            >
              <template #item.risk_escalation="{ item }">
                <div class="pt-3">
                  <v-progress-circular
                    :color="getColor(item)"
                    :width="5"
                    :value="item.risk_escalation"
                    :size="50"
                  >
                    <span> {{ item.risk_escalation }}% </span>
                  </v-progress-circular>
                </div>
              </template>
              <template #item.content="{ item }">
                <div class="pt-3">
                  {{ item.content }}
                </div>
                <div v-if="item.tags && item.tags.length" class="pb-3">
                  <template v-for="tag in item.tags">
                    <v-chip
                      :key="tag.id"
                      class="mt-5 mb-2 mr-2"
                      :color="tag.color"
                      label
                      text-color="white"
                    >
                      <v-icon left>
                        {{ tag.icon }}
                      </v-icon>
                      {{ tag.name }}
                    </v-chip>
                    <!-- <v-chip class="ma-2" color="primary" label>
                    <v-icon left> mdi-account-circle-outline </v-icon>
                    John Leider
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    close
                    color="cyan"
                    label
                    text-color="white"
                  >
                    <v-icon left> mdi-twitter </v-icon>
                    New Tweets
                  </v-chip> -->
                  </template>
                </div>
              </template>
              <template #item.date="{ item }">
                <div class="pa-3">
                  <span> {{ daysAgo(item) }} </span>
                </div>
              </template>
            </v-data-table>
          </v-card>
          <!-- <v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              lg="12"
              xs="12"
            >
              <builder />
            </v-col> -->
          <v-col>
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              persistent
              :no-click-animation="true"
            >
              <v-toolbar dark color="primary" class="pl-5">
                <v-btn icon dark @click="closeDialog()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  ORR Model Builder - Build an AI model to find correlations in
                  ORR data and simulate and predict future data
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <builder v-if="model && model.datasets" :key="model.model_code" />
            </v-dialog>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
// eslint-disable-next-line import/no-unresolved
import { createModel } from "@/lib/model";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import tickets from "../../data/tickets.json";
import topics from "../../data/topics.json";
import filterElement from "../../data/ticket-filter.layout.json";

console.log("tickets", tickets);

dayjs.extend(relativeTime);

const modelNamespace = createNamespacedHelpers("model");
export default {
  name: "ModelBuilderView",
  components: {
    Builder: () => import("@/components/model/builder"),
    ElementRenderer: () => import("@/components/renderer/element-renderer.vue"),
    WordCloud: () => import("@/components/chart/wordcloud/chart.vue"),
  },
  data() {
    return {
      dialog: false,
      number: null,
      search: null,
      filterMenu: false,
      tickets,
      topics,
      headers: [
        { text: "Risk Of Escalation", value: "risk_escalation", width: "10%" },
        { text: "Home Type", value: "home_type", width: "10%" },
        { text: "Content", value: "content" },
        { text: "Receipt Date", value: "date", width: "10%" },
      ],
      filterElement,
      dataset: {
        table_name: 'hello',
      },
    };
  },
  computed: {
    ...modelNamespace.mapState({
      model: (state) => state.model,
      models: (state) => state.models,
      loading: (state) => state.loading,
    }),
  },
  mounted() {
    this.loadModels();
  },
  methods: {
    ...modelNamespace.mapActions(["aLoadModels", "aCreateModel"]),
    ...modelNamespace.mapMutations([
      "mSetDataset",
      "mSetModel",
      "mResetModelAndDataset",
    ]),
    getColor(item) {
      if (item.risk_escalation >= 80) {
        return "red";
      } else if (item.risk_escalation >= 50 && item.risk_escalation < 80) {
        return "yellow";
      }
      return "green";
    },
    daysAgo(item) {
      return dayjs(item.date).fromNow();
    },
    loadModels() {
      this.aLoadModels();
    },
    closeDialog() {
      this.dialog = false;
      this.loadModels();
      this.mResetModelAndDataset();
    },
    async createModel(step) {
      const model = createModel();
      this.mSetModel(model);
      this.goToStep(step);
    },
    setModel(item) {
      this.dialog = true;
      try {
        // item
        const model = JSON.parse(item.ui_json);
        this.mSetModel(model);
        this.goToStep(1);
      } catch (error) {}
    },
    newModel() {
      this.dialog = true;
      this.createModel(1);
    },
    goToStep(step) {
      // console.log('model', this.model);
      this.setActiveDataset(this.model.datasets[step - 1]);
    },
    setActiveDataset(dataset) {
      // this.activeDataset = { ...dataset };
      // alert(dataset.axis);
      this.mSetDataset(dataset);
    },
  },
};
</script>

<style scoped>
.side-text {
  margin-top: 10px;
  padding: 20px;
}
.banner-wrapper {
  padding-top: 50px;
}
@media (min-width: 960px) {
  .side-text {
    margin-top: 200px;
    padding: 20px;
  }
  .banner-wrapper {
    padding-top: 100px;
  }
}
.filter-container {
  max-width: 450px;
  background: white;
}
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.v-tab {
  text-transform: none;
}
</style>
